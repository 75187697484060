<template>
  <div class="gallery">
    <NavigationDrawer
        v-model="selectedComponent"
        :subvalue="selectedSubcomponent"
        :tabs="tabs"
        @subinput="selectedSubcomponent = $event"
    />
    <div class="gallery-component">
      <h2 v-if="selectedComponent">{{ getTitle() }}</h2>
      <h2 v-else>Select a gallery</h2>
      <component :is="getComponent()"/>
    </div>
  </div>
</template>

<script>
import NavigationDrawer from "../NavigationDrawer.vue";
import ButtonGallery from "./ButtonGallery.vue";
import FormulareGallery from "./FormulareGallery.vue";
import ColorsGallery from "./ColorsGallery.vue";
import OtherGallery from "./OtherGallery.vue";
import TagGallery from "./TagGallery.vue";
import ColorConversionGallery from "./ColorConversionGallery.vue";
import NavigationGallery from "./NavigationGallery.vue";
import SearchGallery from "./SearchGallery.vue";
import SimpleSearchGallery from "./SimpleSearchGallery.vue";

export default {
  name: "ComponentGallery",
  components: {
    NavigationDrawer,
    ButtonGallery,
    FormulareGallery
  },
  data() {
    return {
      selectedComponent: 'brandLibrary',
      selectedSubcomponent: 'colors',
      tabs: [
        { name: 'brandLibrary',
          label: 'Brand Library',
          subtabs: [
            { name: 'colors', label: 'Farben' },
            { name: 'buttons', label: 'Buttons' },
            { name: 'tags', label: 'Tags' },
            { name: 'simpleSearch', label: 'Simple Search' },
            { name: 'formulare', label: 'Formulare' },
          ]
        },
        { name: 'components',
          label: 'Komponenten',
          subtabs: [
            { name: 'navigation', label: 'Navigation' },
            { name: 'search', label: 'Search' },
          ]
        },
        { name: 'darkMode',
          label: 'Dark Mode',
          subtabs: [
            { name: 'colorConversion', label: 'Color Conversion' },
          ]
        },
        { name: 'otherComponents',
          label: 'Other Components',
          subtabs: [
            { name: 'other', label: 'Other' },
          ]
        },
      ],
      componentMap: {
        colors: ColorsGallery,
        buttons: ButtonGallery,
        tags: TagGallery,
        simpleSearch: SimpleSearchGallery,
        formulare: FormulareGallery,
        colorConversion: ColorConversionGallery,
        otherComponents: OtherGallery,
        navigation: NavigationGallery,
        search: SearchGallery,
      },
    };
  },
  methods: {
    getComponent() {
      if (this.selectedSubcomponent && this.componentMap[this.selectedSubcomponent]) {
        return this.componentMap[this.selectedSubcomponent];
      }
      return this.componentMap[this.selectedComponent];
    },
    getTitle() {
      for (let tab of this.tabs) {
        let subtab = tab.subtabs.find(subtab => subtab.name === this.selectedSubcomponent);
        if (subtab) {
          return subtab.label;
        }
      }
      return null;
    },
  },
}
</script>
<style scoped>
@use '../../../../../frontend/sass/components/_componentGallery.scss';
</style>