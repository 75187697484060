<template>
  <div id="press_notification_form" class="press_notification_form flexible_container">
    <div class="press_notification_form__header">
      {{ $t('genios.monitoring.press.headerText') }}
    </div>
    <div class="press_notification_form__description">
      {{ $t('genios.monitoring.press.description.first', {sourceName: sourceName}) }} <br>
      {{ $t('genios.monitoring.press.description.second') }}
    </div>
    <form @submit.prevent="submitForm" class="press_notification_form__form">
      <div class="monitoring_form__form__settings__schedule">
        <div v-for="(email,index) in emails">
          <div class="press_notification_form__form__emails">
            <text-form-element
                :passed-value="email.value"
                :element="email"
                :focus="focused"
                v-on:focus="focused=$event"
                v-on:modified="email.value = ($event === null ? '' : $event.trim())"
            />
            <Button
                type="button"
                variant="icon"
                :style="emails.length <= 1 && 'visibility: hidden; pointer-event: none'"
                @click="emails.splice(index, 1)"
                icon="trash-outline"
            />
            <Button
                type="button"
                variant="icon"
                @click="emails.push({
                      id: 'press_notification_form__email_' + index+1,
                      field: 'email_' + index+1,
                      fieldType: 'TEXT',
                      fieldLabel: $t('genios.monitoring.label.email'),
                      value: '',
                      error: ''
            })"
                icon="add-outline"
            />
          </div>
        </div>

        <Error class="press_notification_form__error" v-show="error" :error="error"/>

        <!-------------Emails Group Part------------>

        <email-group-action :checked-emails-group="checkedEmailsGroup" :shared-state="sharedState"/>

        <!-------------Emails Group Part------------>

      </div>
      <div class="press_notification_form__submit_container">
        <Button
            name="close-modal"
            :text="$t('genios.monitoring.cancelText')"
            type="button"
            @click="closeModal"
            variant="secondary"
            :toggle-text="false"
        />
        <Button
            name="submit1"
            :text="$t('genios.monitoring.press.submitText')"
            variant="primary"
            id="submit1"
            ref="submitButton"
            :toggle-text="false"
        />
      </div>
    </form>
  </div>
</template>

<script>
import {closeTransparentPopupModal} from "../../../functions/closing";
import Button from "../styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import i18n from "../plugins/Translations.vue";
import axios from "axios";
import Error from "../styled/Error.vue";
import {emailsComputedGroupMixin, emailsDataGroupMixin, emailsGroupMixin} from "../../../functions/mixins/emailsGroup";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import EmailGroupAction from "./EmailGroupAction.vue";

export default {
  name: "PressNotificationForm",
  components: {
    EmailGroupAction,
    CheckboxFormElement, Error, TextFormElement, Button
  },
  mixins: [emailsDataGroupMixin, emailsGroupMixin, emailsComputedGroupMixin],
  props: {
    dbShortcut: {
      type: String,
      required: true
    },
    sourceName: {
      type: String,
      required: true
    },
    sharedState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      active: true,
      emails: [{
        id: 'press_notification_form__email_' + 0,
        field: 'email_' + 0,
        fieldType: 'TEXT',
        fieldLabel: i18n.t("genios.monitoring.label.email"),
        value: '',
        error: ''
      }],
      focused: 'press_notification_form__email_' + 0,
      error: ''
    }
  },
  mounted() {
    if (this.defaultUserEmail) {
      this.emails[0].value = this.defaultUserEmail
      this.focused = ''
      this.$refs.submitButton.$el.focus()
    }
  },
  computed: {
    defaultUserEmail: function () {
      return this.sharedState.defaultUserEmail || '';
    },
    magazineProfile: function () {
      return {
        active: true,
        database: this.dbShortcut,
        emails: [...this.emails.map(email => email.value), ...this.emailsToSend]
      }
    }
  },
  methods: {
    async submitForm() {
      this.validate()

      if (!this.error) {
        await axios
            .post("/api/magazineProfile", this.magazineProfile)
            .then((response) => {
              if (response.data) {
                if (response.data.responseCode !== "OK") {
                  this.error = i18n.t('genios.monitoring.error.' + response.data.responseCode)
                }
              }
            })
            .catch(error => {
              this.error = i18n.t('genios.monitoring.error.connection')
              console.log(error)
            });
      }

      if (!this.error) {
        this.closeModal()
      }
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    validate() {
      this.error = ''
      this.emails.forEach(item => item.error = '')

      this.emails.forEach(email => {
        if (!this.emailIsValid(email.value))
          this.error = i18n.t('genios.monitoring.error.emailNotValid')
      })
    },
    closeModal() {
      closeTransparentPopupModal();
    }
  },
}
</script>