var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-container__title"},[_vm._v(_vm._s(_vm.$t('genios.sendEmail.header')))]),_vm._v(" "),_c('div',{staticClass:"modal-container__content--container space_above"},[_c('div',{staticClass:"send_email__form__line_1 modal-container__content--list"},[_c('div',[_vm._v(_vm._s(_vm.$t('genios.sendEmail.link')))]),_vm._v(" "),_vm._l((_vm.emailForm.emailsTo),function(email,index){return _c('div',{staticClass:"send_email__form__emailsTo"},[_c('div',{staticClass:"send_email__form__emailsTo__input-item"},[_c('text-form-element',{attrs:{"passed-value":email.value,"element":email,"focus":_vm.focus,"error":!!_vm.error[`email_${index}`]},on:{"focus":function($event){_vm.focus = $event},"modified":function($event){email.value = ($event === null ? '' : $event.trim())}}}),_vm._v(" "),(_vm.emailForm.emailsTo.length > 1)?_c('Button',{attrs:{"type":"button","variant":"icon","icon":"trash-outline"},on:{"click":function($event){return _vm.emailForm.emailsTo.splice(index, 1)}}}):_vm._e(),_vm._v(" "),_c('Button',{style:(index !== _vm.emailForm.emailsTo.length-1 && 'visibility:hidden'),attrs:{"type":"button","variant":"icon","icon":"add-outline"},on:{"click":function($event){_vm.emailForm.emailsTo.splice(index + 1, 0, {
            id: 'emailTo' + index+1,
            field: 'emailTo'+index+1,
            fieldType: 'text',
            fieldLabel: _vm.$t('genios.sendEmail.recipients'),
            value: ''
      })}}})],1),_vm._v(" "),(_vm.error[`email_${index}`])?_c('Error',{attrs:{"error":_vm.error[`email_${index}`]}}):_vm._e()],1)})],2),_vm._v(" "),_c('div',{staticClass:"send_email__form__body"},[_c('TextFormElement',{attrs:{"passed-value":_vm.emailForm.emailFrom.value,"element":_vm.emailForm.emailFrom,"focus":_vm.focus,"error":!!_vm.error.email_emailFrom},on:{"focus":function($event){_vm.focus = $event},"modified":function($event){_vm.emailForm.emailFrom.value = ($event === null ? '' : $event.trim())}}}),_vm._v(" "),(_vm.error.email_emailFrom)?_c('Error',{attrs:{"error":_vm.error.email_emailFrom}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"send_email__form__message"},[_c('TextAreaFormElement',{attrs:{"passed-value":_vm.emailForm.message.value,"element":_vm.emailForm.message,"focus":_vm.focus},on:{"focus":function($event){_vm.focus = $event},"modified":function($event){_vm.emailForm.message.value = $event}}})],1),_vm._v(" "),_c('p',{staticClass:"send_email__form__privacy"},[_vm._v("\n          "+_vm._s(_vm.$t('genios.sendEmail.privacy'))+"\n        ")])],1),_vm._v(" "),_c('p',{staticClass:"send_email__form__mandatory"},[_vm._v("\n        "+_vm._s(_vm.$t('genios.sendEmail.mandatory'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"modal-container__footer"},[(_vm.error.general)?_c('Error',{attrs:{"error":_vm.error.general}}):_vm._e(),_vm._v(" "),_c('Button',{attrs:{"name":"close-modal","text":_vm.$t('genios.sendEmail.cancelText'),"type":"button","variant":"secondary","toggle-text":false},on:{"click":_vm.closeModal}}),_vm._v(" "),_c('Button',{ref:"submitBtn",attrs:{"text":_vm.$t('genios.sendEmail.submitText'),"variant":"primary","id":"submit1","name":"submit1","position":"left","toggle-text":false,"disabled":_vm.isLoading}},[(_vm.isLoading)?_c('ButtonLoader',{attrs:{"size":_vm.$refs.submitBtn.$el.clientHeight}}):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }