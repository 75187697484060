<template>
  <form @submit.prevent="submitForm">
    <div class="modal-container">
      <div class="modal-container__title">{{ $t('genios.sendEmail.header') }}</div>
      <div class="modal-container__content--container space_above">
        <div class="send_email__form__line_1 modal-container__content--list">
          <div>{{ $t('genios.sendEmail.link') }}</div>
          <div class="send_email__form__emailsTo" v-for="(email, index) in emailForm.emailsTo">
            <div class="send_email__form__emailsTo__input-item">
              <text-form-element
                  :passed-value="email.value"
                  :element="email"
                  :focus="focus"
                  :error="!!error[`email_${index}`]"
                  @focus="focus = $event"
                  @modified="email.value = ($event === null ? '' : $event.trim())"
              />
              <Button
                  type="button"
                  variant="icon"
                  v-if="emailForm.emailsTo.length > 1"
                  @click="emailForm.emailsTo.splice(index, 1)"
                  icon="trash-outline"
              />
              <Button
                  :style="index !== emailForm.emailsTo.length-1 && 'visibility:hidden'"
                  type="button"
                  variant="icon"
                  @click="emailForm.emailsTo.splice(index + 1, 0, {
              id: 'emailTo' + index+1,
              field: 'emailTo'+index+1,
              fieldType: 'text',
              fieldLabel: $t('genios.sendEmail.recipients'),
              value: ''
        })"
                  icon="add-outline"
              />
            </div>
            <Error v-if="error[`email_${index}`]" :error="error[`email_${index}`]"/>
          </div>
        </div>
        <div class="send_email__form__body">
          <TextFormElement
              :passed-value="emailForm.emailFrom.value"
              :element="emailForm.emailFrom"
              :focus="focus"
              :error="!!error.email_emailFrom"
              @focus="focus = $event"
              @modified="emailForm.emailFrom.value = ($event === null ? '' : $event.trim())"
          />
          <Error v-if="error.email_emailFrom" :error="error.email_emailFrom"/>
          <div class="send_email__form__message">
            <TextAreaFormElement
                :passed-value="emailForm.message.value"
                :element="emailForm.message"
                :focus="focus"
                @focus="focus = $event"
                @modified="emailForm.message.value = $event"
            />
          </div>
          <p class="send_email__form__privacy">
            {{ $t('genios.sendEmail.privacy') }}
          </p>
        </div>
        <p class="send_email__form__mandatory">
          {{ $t('genios.sendEmail.mandatory') }}
        </p>
      </div>
      <div class="modal-container__footer">
        <Error v-if="error.general" :error="error.general"/>
        <Button
            name="close-modal"
            :text="$t('genios.sendEmail.cancelText')"
            type="button"
            @click="closeModal"
            variant="secondary"
            :toggle-text="false"
        />
        <Button
            :text="$t('genios.sendEmail.submitText')"
            variant="primary"
            id="submit1"
            name="submit1"
            position="left"
            ref="submitBtn"
            :toggle-text="false"
            :disabled="isLoading"
        >
          <ButtonLoader
              v-if="isLoading"
              :size="$refs.submitBtn.$el.clientHeight"
          />
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

import TextFormElement from "../FormElements/TextFormElement.vue";
import TextAreaFormElement from "../FormElements/TextAreaFormElement.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";
import Error from "../styled/Error.vue";
import ButtonLoader from "../styled/ButtonLoader.vue";
import {closeTransparentPopupModal} from "../../../functions/closing";
import store from "../../../functions/store/modules";

export default {
  name: "SendEmailOverlayContent",
  components: {
    Error,
    TextFormElement,
    Button,
    TextAreaFormElement,
    ButtonLoader,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "general",
    },
    ids: {
      type: Array,
      required: true
    },
    documents: {
      type: Array,
      required: false,
      default: null
    },
    selectedListId: {
      type: Number,
      required: false
    }
  },
  methods: {
    closeModal() {
      closeTransparentPopupModal();
    },
    async submitForm() {
      this.validate();

      if (Object.keys(this.error).length < 1) {
        this.isLoading = true;

        const { sendObject } = this;

        if (this.documents) {
          sendObject.requestList = this.documents.filter(i => i);
        } else {
          sendObject.requestList = this.ids?.map(id => {
            let item = window.sharedState?.searchResult?.find(doc => doc.documentId === id);
            if (!item) return null;
            return {
              documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
            };
          }).filter(i => !!i);
        }

        sendObject.emailFrom = this.emailForm.emailFrom.value;
        sendObject.emailsTo = this.emailForm.emailsTo.map(item => item.value);
        sendObject.message = this.emailForm.message.value;

      if (this.type === "bookmark-list") {
        const storedStoring = store.getters['getUserInteractionBookmarksResultSettings'].sortAndOrder;
        const sort = storedStoring.sort;
        const order = storedStoring.order;

        sendObject.bookmarkListId = this.selectedListId;
        sendObject.sort = sort;
        sendObject.order = order;
        sendObject.size = 100;
      }

      const emailEndpoint = this.type === "bookmark-list" ? "/api/sendBookmarkListEmail" : "/api/sendEmail";

        try {
          const { data } = await axios.post(emailEndpoint, sendObject);
          if (!data.ok) {
            this.error = { general: i18n.t('genios.monitoring.error.' + data.responseCode) };
          }
        } catch (error) {
          this.error = { general: i18n.t('genios.monitoring.error.connection') };
          console.error("sendEmail:submitForm:error =>", error);
        } finally {
          this.isLoading = false;
        }

        if (Object.keys(this.error).length < 1) {
          this.closeModal();
        }
      }
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validate: function () {
      this.error = {};
      const {emailsTo} = this.emailForm;

      if (emailsTo.length) {
        emailsTo.forEach((item, index) => {
          if (!this.emailIsValid(item.value)) {
            this.error['email_' + index] = i18n.t('genios.monitoring.error.emailNotValid');
          }
        });
      }

      if (!this.emailForm.emailFrom.value || !this.emailIsValid(this.emailForm.emailFrom.value)) {
        this.error['email_emailFrom'] = i18n.t('genios.monitoring.error.emailNotValid');
      }
    }
  },
  data() {
    return {
      error: {},
      scrollBarOptions: {
        scrollbars: {
          autoHide: 'move',
          clickScrolling: true,
          autoHideDelay: 200
        },
        overflowBehavior: {x: 'hidden'}
      },
      focus: null,
      isLoading: false,
      emailForm: {
        emailsTo: [0].map((item, index) => {
              return {
                id: 'emailTo' + index,
                field: 'emailTo',
                fieldLabel: i18n.t('genios.sendEmail.recipients'),
                fieldType: 'text',
                value: ""
              };
            }
        ),
        emailFrom: {
          id: 'emailFrom',
          field: 'emailFrom',
          fieldLabel: i18n.t('genios.sendEmail.sender'),
          fieldType: 'text',
          value: window.sharedState.defaultUserEmail || ""
        },
        message: {
          id: 'message',
          field: 'message',
          fieldLabel: i18n.t('genios.sendEmail.messageLabel'),
          value: ""
        }
      },
      sendObject: {
        emailFrom: "",
        emailsTo: [],
        message: "",
        documents: [{document: this.document}]
      },
      document: {
        documentId: "",
        publisher: "",
        year: "",
        price: "",
        title: "",
        image: "",
        type: ""
      }
    };
  },
};
</script>

<style scoped>
.modal-container__content--container {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 15px;
  overflow-x: hidden;
}

.modal-container__footer .button__primary:disabled {
  background-color: var(--primary-color);
  color: white;
}

.send_email__form__emailsTo__input-item {
  padding-left: 5px;
}
</style>